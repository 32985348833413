.wrapText{
  white-space: normal !important;
}
.tableiAssure{
  width: 99.9%;
  background-color: transparent;
  border-collapse: collapse;
  border-spacing: 0;
  display: table;
  box-sizing: border-box;
  border-spacing: 2px;
  border-color: grey;
}
.tableiAssure>tbody>tr>td, .tableiAssure>tbody>tr>th, .tableiAssure>tfoot>tr>td, .tableiAssure>tfoot>tr>th, .tableiAssure>thead>tr>td, .tableiAssure>thead>tr>th {
    padding: 12px;
    line-height: 1.42857143;
    vertical-align: top;
}
.tableiAssure-striped>tbody>tr:nth-of-type(odd) {
    background-color: #f9f9f9;
}
.tableDwld{
  font-size: 25px;
  border: 1px solid #eee;max-width: 100%;
  
  padding: 5px 8px;
  box-shadow: 0 0 10px #ede1e1;
  color: #777;
  background-color: #fff;
  margin-bottom: 10px;
  margin-top: 28px;
  border: 1px solid #979797 !important;
  border-radius: 3px !important;
  height: 32px !important;
}
.iAssureITtable-bordered > thead > tr > th, .iAssureITtable-bordered > tbody > tr > th, .iAssureITtable-bordered > tfoot > tr > th, .iAssureITtable-bordered > thead > tr > td, .iAssureITtable-bordered > tbody > tr > td, .iAssureITtable-bordered > tfoot > tr > td {
    border: 1px solid #fff6;
}
.table-striped>tbody>tr:nth-of-type(even) {
    background-color: #fff;
}
.iAssureITtable-bordered{
  border: 1px solid var(--blue-color );
}
.tempTableHeader{
  background: var(--blue-color ) !important;
}
.paginationAdminWrap{
  text-align: center !important;
  width: 100%;
}
.paginationAdminOES > li > a{
  margin-right: 5px !important;
  margin-bottom: 5px !important;
}
.paginationAdminOES > li{
    display: inline-block;
    margin-left: 0 !important;
    font-size: 10px;
}
.queDataCircle p{
  height: 100%;
  width: 100%;
}
.activeCircle{
  background: #7f7f7f !important;
}
.queDataCircle {
  padding-top: 4px;
  font-size: 12px;
  border: 1px solid #e8e6e6 !important;
  height: 24px !important;
  width: 24px !important;
  border-radius: 100% !important;
  box-shadow: 3px 4px 7px -3px #aeabab !important;
  text-align: center;
}
.marginTop8{
  margin-top: 8px;
}
.marginTop17{
  margin-top: 17px;
}
.textAlignCenter{
  text-align: center !important;
}
.tempTableHeader{
  color: #fff;
}

.umDynamicHeader{
  /*white-space: nowrap !important;*/
}
.noPadding{
  padding: 0px;
}

.noWrapText{
  white-space: nowrap;
}
.tableicons{
  font-size: 20px;
    padding: 5px 8px;
    box-shadow: 0 0 10px #ede1e1;
    color: #777;
    background-color: #fff;
    border: 1px solid #979797 !important;
    border-radius: 3px !important;
}
.tableprintincon{
  font-size: 15px;
    box-shadow: 0 0 10px #ede1e1;
    color: #777 !important;
    background-color: #fff;
    border: 1px solid #979797 !important;
    border-radius: 3px !important;
    padding: 5px 10px;
}
.totalrow{
  background-color: #ddd;
}
#tableComponent{
  margin-top:15px;
}
#HideTable{
  display: none;
}
.borderAll{
  border:2px solid #eee;
}
.widthFix{
  width: 65px;
}
.widthFixAdd{
  width: 80px;
  word-break: break-word;

}
.fixedWidth{
  width: 150px;
  word-break: break-word;

}