.sidebarMenuTitle{
  font-size: 14px;
  font-family: 'Montserrat',sans-serif !important;
  
}

.sidebar-menu > li.active > a.active,
.sidebar-menu > li > a.active {
    color: #ffffff;
    background: #1e282c;
}
.skin-blue .sidebar-menu > li > a.active,
.skin-blue .sidebar-menu > li.active > a.active {
    border-left-color: #3c8dbc;
}

.sidebar-menu .treeview li a.active{
  color: #fff;
  font-size: 12px !important;  
}
.active-submenu{
  color: #fff;
}
.hold-transition .content-wrapper,
.hold-transition .right-side,
.hold-transition .main-footer,
.hold-transition .main-sidebar,
.hold-transition .left-side,
.hold-transition .main-header .navbar,
.hold-transition .main-header .logo,
.hold-transition .menu-open .fa-angle-left {
   /* Fix for IE */
   -webkit-transition: 0.5s;
   -o-transition: 0.5s;
   transition: 0.5s;
}
/*.main-sidebar:hover{
  overflow: visible !important;
}*/
.skin-blue .sidebar-menu .treeview .treeview-menu>li>a:hover {
    color: #ffffff !important;
    /*background: #b3b5bb36 !important;*/
}
.skin-blue .sidebar-menu > li.active > a,
.skin-blue .sidebar-menu > li.menu-open > a {
  color: #ffffff;
  background: #1e282c;
  /*border-bottom: 2px solid #2c3245;*/
  border-left-color: #3c8dbc;
}
.skin-blue .sidebar-menu > li:hover > a{
  color: #ffffff;
  background: #1e282c;
}
.main-sidebar {
  position: absolute;
  top: 0;
  left: 0;
  padding-top: 50px;
  min-height: 100%;
  width: 230px;
  z-index: 810;
  -webkit-transition: -webkit-transform 0.3s ease-in-out, width 0.3s ease-in-out;
  -moz-transition: -moz-transform 0.3s ease-in-out, width 0.3s ease-in-out;
  -o-transition: -o-transform 0.3s ease-in-out, width 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, width 0.3s ease-in-out;
  /*overflow: hidden;*/
}
@media (max-width: 767px) {
  .main-sidebar {
    padding-top: 100px;
  }
}
@media (max-width: 767px) {
  .main-sidebar {
    -webkit-transform: translate(-230px, 0);
    -ms-transform: translate(-230px, 0);
    -o-transform: translate(-230px, 0);
    transform: translate(-230px, 0);
    overflow: visible !important;
  }
}
@media (min-width: 768px) {
  .sidebar-collapse .main-sidebar {
    -webkit-transform: translate(-230px, 0);
    -ms-transform: translate(-230px, 0);
    -o-transform: translate(-230px, 0);
    transform: translate(-230px, 0);
    overflow: visible !important;
  }
}
@media (max-width: 767px) {
  .sidebar-open .main-sidebar {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    overflow: visible !important;
  }
}

.sidebar {
  padding-bottom: 10px;
}
.sidebar-form input:focus {
  border-color: transparent;
}
.user-panel {
  position: relative;
  width: 100%;
  padding: 10px;
  overflow: hidden;
}
.user-panel:before,
.user-panel:after {
  content: " ";
  display: table;
}
.user-panel:after {
  clear: both;
}
.user-panel > .image > img {
  width: 100%;
  max-width: 45px;
  height: auto;
}
.user-panel > .info {
  padding: 5px 5px 5px 15px;
  line-height: 1;
  position: absolute;
  left: 55px;
}
.user-panel > .info > p {
  font-weight: 600;
  margin-bottom: 9px;
}
.user-panel > .info > a {
  text-decoration: none;
  padding-right: 5px;
  margin-top: 3px;
  font-size: 11px;
}
.user-panel > .info > a > .fa,
.user-panel > .info > a > .ion,
.user-panel > .info > a > .glyphicon {
  margin-right: 3px;
}
.sidebar-menu {
  list-style: none;
  margin: 0;
  padding: 0;
  font-family: 'MYRIADPROREGULAR';
  letter-spacing: 0.5px;
  overflow-y: scroll;
}
.sidebar-menu > li {
  position: relative;
  margin: 0;
  padding: 0;
  margin-bottom: 2px !important;
}
.sidebar-menu > li > a {
  padding: 12px 5px 12px 15px;
  display: block;
}
.sidebar-menu > li > a > .fa,
.sidebar-menu > li > a > .glyphicon,
.sidebar-menu > li > a > .ion {
  width: 27px;
}
.sidebar-menu > li .label,
.sidebar-menu > li .badge {
  margin-right: 5px;
}
.sidebar-menu > li .badge {
  margin-top: 3px;
}
.sidebar-menu li.header {
  padding: 10px 25px 10px 15px;
  font-size: 12px;
}
.sidebar-menu li > a > .fa-angle-left,
.sidebar-menu li > a > .pull-right-container > .fa-angle-left {
  width: auto;
  height: auto;
  padding: 0;
  margin-right: 10px;
  -webkit-transition: transform 0.5s ease;
  -o-transition: transform 0.5s ease;
  transition: transform 0.5s ease;
}
.sidebar-menu li > a > .fa-angle-left {
  position: absolute;
  top: 50%;
  right: 10px;
  margin-top: -8px;
}
.sidebar-menu .menu-open > a > .fa-angle-left,
.sidebar-menu .menu-open > a > .pull-right-container > .fa-angle-left {
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  transform: rotate(-90deg)!important;
}
.sidebar-menu .active > .treeview-menu {
  display: block;
}
/*
* Component: Sidebar Mini
*/
@media (min-width: 768px) {
  .sidebar-mini.sidebar-collapse .content-wrapper,
  .sidebar-mini.sidebar-collapse .right-side,
  .sidebar-mini.sidebar-collapse .main-footer {
    margin-left: 50px !important;
    z-index: 840;
  }
  .sidebar-mini.sidebar-collapse .main-sidebar {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    width: 53px !important;
    z-index: 850;
  }
  .sidebar-mini.sidebar-collapse .sidebar-menu > li: {
    position: relative;
  }
  .sidebar-mini.sidebar-collapse .sidebar-menu > li > a {
    margin-right: 0;
  }
  .sidebar-mini.sidebar-collapse .sidebar-menu > li > a > span {
    border-top-right-radius: 10px;
  }
  .sidebar-mini.sidebar-collapse .sidebar-menu > li:not(.treeview) > a > span {
    border-bottom-right-radius: 4px;
  }
  .sidebar-mini.sidebar-collapse .sidebar-menu > li > .treeview-menu {
    /*padding-top: 5px;*/
    /*padding-bottom: 5px;*/
    border-bottom-right-radius: 4px;
  }
  .sidebar-mini.sidebar-collapse .main-sidebar .user-panel > .info,
  .sidebar-mini.sidebar-collapse .sidebar-form,
  .sidebar-mini.sidebar-collapse .sidebar-menu > li > a > span,
  .sidebar-mini.sidebar-collapse .sidebar-menu > li > .treeview-menu,
  .sidebar-mini.sidebar-collapse .sidebar-menu > li > a > .pull-right,
  .sidebar-mini.sidebar-collapse .sidebar-menu li.header {
    display: none !important;
    -webkit-transform: translateZ(0);
  }
.sidebar-mini.sidebar-collapse .main-header .logo {
    width: 50px;
  }
  .sidebar-mini.sidebar-collapse .main-header .logo > .logo-mini {
    display: block;
    margin-left: -15px;
    margin-right: -15px;
    font-size: 18px;
  }
  .sidebar-mini.sidebar-collapse .main-header .logo > .logo-lg {
    display: none;
  }
  .sidebar-mini.sidebar-collapse .main-header .navbar {
    margin-left: 50px;
  }
}
.sidebar-mini:not(.sidebar-mini-expand-feature).sidebar-collapse .sidebar-menu > li:hover > a > span:not(.pull-right),
.sidebar-mini:not(.sidebar-mini-expand-feature).sidebar-collapse .sidebar-menu > li:hover > .treeview-menu {
  display: block !important;
  position: absolute;
  width: 235px;
  left: 50px;
}
.sidebar-mini:not(.sidebar-mini-expand-feature).sidebar-collapse .sidebar-menu > li:hover > a > span {
  top: 0;
  margin-left: -3px;
  padding: 12px 5px 12px 20px;
  background-color: inherit;
}
.sidebar-mini:not(.sidebar-mini-expand-feature).sidebar-collapse .sidebar-menu > li:hover > a > .pull-right-container {
  position: relative !important;
  float: right;
  width: auto !important;
  left: 230px !important;
  top: -22px !important;
  z-index: 900;
}
.sidebar-mini:not(.sidebar-mini-expand-feature).sidebar-collapse .sidebar-menu > li:hover > a > .pull-right-container > .label:not(:first-of-type) {
  display: none;
}
.sidebar-mini:not(.sidebar-mini-expand-feature).sidebar-collapse .sidebar-menu > li:hover > .treeview-menu {
  top: 44px;
  margin-left: 0;
}
.sidebar-expanded-on-hover .main-footer,
.sidebar-expanded-on-hover .content-wrapper {
  margin-left: 50px;
}
.sidebar-expanded-on-hover .main-sidebar {
  box-shadow: 3px 0 8px rgba(0, 0, 0, 0.125);
}
.sidebar-menu,
.main-sidebar .user-panel,
.sidebar-menu > li.header {
  /*white-space: nowrap;*/
  overflow: hidden;
}
.sidebar-menu:hover {
  overflow: visible;
}
.sidebar-form,
.sidebar-menu > li.header {
  overflow: hidden;
  text-overflow: clip;
}
.sidebar-menu li > a {
  position: relative;
}
.sidebar-menu li > a > .pull-right-container {
  position: absolute;
  right: 5px;
  top: 50%;
  margin-top: -7px;
}
.skin-blue .main-header .logo {
  /*background-color: #367fa9;*/
  background-color: #222d32;
  color: #ffffff;
  border-bottom: 0 solid transparent;
}
.skin-blue .wrapper,
.skin-blue .main-sidebar,
.skin-blue .left-side {
  background-color: #222d32;
}
.skin-blue .sidebar-menu > li > .treeview-menu {
  margin: 0 0px;
  background: #2c3b41;
  padding-left: 0px;
}
.activeLi{
      background: #b3b5bb36;
    border-left: 3px solid #fff;
}
a:hover, a:focus{
  text-decoration: none;
}
.activetree{
  background: #1e282c !important;
  color: #fff !important;
}

.activeSubtree{
  background: #b3b5bb36;
  border-left: 3px solid #fff;
}
.sidebarWrapper{
  padding: 0;
  margin-top: 4em;
}

.panel-default > .panel-heading {
    color: #333;
    background-color: #2b2b2b;
    border-color: #ddd;
    margin-bottom: 5px;
    height: 45px;
}
.panel-heading {
    padding: 10px 15px;
     border-bottom: 0px solid transparent !important; 
     border-top-left-radius: 0px !important; 
     border-top-right-radius: 0px !important; 
}
.panel {
    margin-bottom: 20px;
    background-color: #fff;
     border: 0px solid transparent !important; 
    border-radius: 4px;
    -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, .05);
    box-shadow: 0 1px 1px rgba(0, 0, 0, .05);
}
.panel-body{
  /*background: #3e3c3c;*/
  /*padding: 10px;*/
  padding-bottom: 0px;
}
.panel-group .panel-heading + .panel-collapse > .panel-body, .panel-group .panel-heading + .panel-collapse > .list-group {
     border-top: 0px solid #ddd; 
}
.skin-blue .sidebar a {
  color: #b8c7ce;
  font-size: 12px !important;
  letter-spacing: 1px;
  /*font-family: -webkit-body;*/
}
.submenu{
    /*background: #2b2b2b;*/
 /*   padding-bottom: 1em;
    padding-top: 1em;*/
}
.subMenuIcon{
  font-size: 10px;
}
.submenuBox{
  padding: 0px;
  padding-top: .5em;
  padding-bottom: .5em;
}
.submenuBox:hover{
  background: #b3b5bb36 !important;
}
.panel{
  background: #222d32 !important;
}
