
.rightsideHeading {
    padding: 10px;
    font-size: 15px;
    display: block;
}

.leftpadd{
    padding-left: 20px;
    margin: 0px !important;
}

#sidebar1 {
    min-width: 250px;
    max-width: 270px;
    background: #333;
    color: #fff;
    transition: all 0.2s;
}

#sidebar1.active {
    min-width: 80px;
    max-width: 80px;
    text-align: center;
}

#sidebar1.active .sidebar1-header h4,  #sidebar1.active .CTAs {
    display: none;
}

#sidebar1.active .sidebar1-header strong {
    display: block;
}

#sidebar1 ul li a {
    text-align: left;
}

#sidebar1.active ul li a {
    padding: 20px 10px;
    text-align: center;
    font-size: 0.85em;
}

#sidebar1.active ul li a i {
    margin-right:  0;
    display: block;
    font-size: 1.8em;
    margin-bottom: 5px;
}

#sidebar1.active ul ul a {
    padding: 10px !important;
}

#sidebar1.active a[aria-expanded="false"]::before, #sidebar1.active a[aria-expanded="true"]::before {
    top: auto;
    bottom: 5px;
    right: 50%;
    transform: translateX(50%);
}

#sidebar1 .sidebar1-header {
    padding: 4px;
    background: #222;
}

#sidebar1 .sidebar1-header strong {
    display: none;
    font-size: 1.8em;
}

#sidebar1 ul li a {
    padding: 10px;
    font-size: 1em;
    display: block;
    background-color: #222d32;
    color:#fff;
}
#sidebar1 ul li a:hover {
    color: #fff;
    background: #1a2226;
}
#sidebar1 ul li a i {
    margin-right: 10px;
}

#sidebar1 ul li.active > a, a[aria-expanded="true"] {
    color: #fff;
    background: #222;
}

a[data-toggle="collapse"] {
    position: relative;
}
ul ul a {
    font-size: 0.9em !important;
    padding-left: 30px !important;
}

ul.CTAs {
    padding: 20px;
}

ul.CTAs a {
    text-align: center;
    font-size: 0.9em !important;
    display: block;
    border-radius: 5px;
    margin-bottom: 5px;
}

a.download {
    background: #fff;
    color: #7386D5;
}

a.article, a.article:hover {
    background: #222 !important;
    color: #fff !important;
}
.addCircle{
    background-color: #dd4b39;
    border-radius: 50%;
    padding: 10px;
}
.yellowColor{
    background-color: #f39c12;
    border-radius: 50%;
    padding: 10px;
}
.greenColor{
    background-color: #00a65a;
    border-radius: 50%;
    padding: 10px;
}
.blueColor{
    background-color: #3c8dbc;
    border-radius: 50%;
    padding: 10px;
}
.aquaColor{
    background-color: #00c0ef;
    border-radius: 50%;
    padding: 10px;
}
.darkGreenColor{
    background-color: #3c763d;
    border-radius: 50%;
    padding: 10px;
}
